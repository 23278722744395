import React, { lazy, useState, useEffect } from "react";
import styles from "./overviewmodule.module.css";
import { Getoverview } from "../../api";
import Loader from "../../uikit/Loader";
import { notify } from "../../uikit/toast";
import { parse, differenceInDays, isWithinInterval } from "date-fns";
import moment from "moment";
import OverViewTitle from "./OverViewTitle";
import OverViewDetails from "./OverViewDetails";
import ApiUseage from "./ApiUseage";
import ApiPurchaseLog from "./ApiPurchaseLog";
import ErrorRate from "./ErrorRate";
import AllApiList from "./AllApiList";
import { Chart } from "chart.js";


// Define types for your data
interface ApiUsageData {
  lable: string[];
  day: number[];
  api_cals: number[];
  error_rate: number[];
  credits_log: number[];
}





interface ErrorRateData {
  date: string;
  statuscode: number;
  Count: number;
}

interface ApiPurchaseData {
  api_name: string;
  description: string;
  expired_date: string;
  credits: string;
  total: string;
  email: string;
  next_billing_date: string;
  paid: boolean;
  payment_date: string;
  download_url: string;
  invoice: string;
  type: string;
  name: string;
}


interface PurchaseData{
  api_name: string;
  total_credit: number;
  credit_used: number;
  available_credit: number;
  payment_date:string;
  expired_date:string;
  addon_count:number;

}


export default function OverviewModule() {
  const [apiDatauseage, setApiDatauseage] = useState<ApiPurchaseData[]>([]);
  const [errorRateData, setErrorRateData] = useState<ErrorRateData[]>([]);
  const [purchase,setPurchase]=useState<PurchaseData[]>([])
  const [chartData, setChartData] = useState<ApiUsageData>();

  const [ResumeParser, setResumeParser] = useState<ApiUsageData>();
  const [ComparativeAnalysis, setComparativeAnalysis] = useState<ApiUsageData>();
  const [AiMatchingAnalysis, setAiMatchingAnalysis] = useState<ApiUsageData>();
  const [AiInterview, setAiInterview] = useState<ApiUsageData>();
  const [ProfileSummary, setProfileSummary] = useState<ApiUsageData>();
  const [AiJdAssistance, setAiJdAssistance] = useState<ApiUsageData>();
  const [JdParser, setJdParser] = useState<ApiUsageData>();
  
  const [callRequest, setcallRequest] = useState<number>(0);
  const [creditsConsumed, setcreditsConsumec] = useState<number>(0);
  const [remainingCredits, setRemainingCredits] = useState<number>(0);
  const [OverallErrorRate, setOverallErrorRate] = useState<number>(0);
  const [loaderCondition, setLoaderCondition] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isDatepicker, setDatepicker] = useState<any>();
  const [ovralldays, setoverallDate] = useState<any>();
  const [isCurrentWeek, setisCurrentWeek] = useState<boolean>(true);
  const [isButtonloader, setButtonloader] = useState<boolean>(false);
  const [loaderHeight, setLoaderHeight] = useState<any>(
    window.innerHeight - 68
  );
  const [innerloader,setinnerloader]=useState(false)

  useEffect(() => {
    const handleResize = () => {
      setLoaderHeight(window.innerHeight - 68);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isScroll, setScroll] = useState(false);
  const handleShow = () => {
    setScroll(true);
  };

  const handleHide = () => {
    setScroll(false);
  };

  const today = moment();
  const initialStartDate = today.clone().subtract(6, "days");

  useEffect(() => {
    setDatepicker({
      startDate: initialStartDate.format("YYYY-MM-DD"),
      endDate: today.format("YYYY-MM-DD"),
    });
    // setLoading(true);
  }, []);

  useEffect(() => {
    setLoaderCondition(true);
    if (loaderCondition) {
      // setLoading(true);
      if (!isDatepicker?.startDate || !isDatepicker?.endDate) {
        return;
      }
      if (isDatepicker?.startDate && isDatepicker?.endDate) {
        const daysBetween = calculateDaysBetweenDates(
          isDatepicker?.startDate,
          isDatepicker?.endDate
        );
        setoverallDate(`${daysBetween + 1}`);
      }

      // Check if the selected date range falls within the current week
      const isCurrentWeek = isWithinCurrentWeek(
        isDatepicker.startDate,
        isDatepicker.endDate
      );

      const param = {
        from_date: isDatepicker?.startDate,
        to_date: isDatepicker?.endDate,
        csv_download: false,
      };
      Getoverview(param)
        .then((res) => {
          setErrorRateData(res.data.error_rate);
          setcallRequest(res.data.total_requests);
          setcreditsConsumec(res.data.total_credits);
          setOverallErrorRate(res.data.total_errate);
          setRemainingCredits(res.data.remaining_credits);
          setPurchase(res.data.purchase_data);
         
          setResumeParser(res.data.resume_parser);
          setJdParser(res.data.jd_parser);
          setComparativeAnalysis(res.data.comparitive_analysis);
          setAiMatchingAnalysis(res.data.matching);
          setAiInterview(res.data.interview_questions);
          setAiJdAssistance(res.data.jd_generation);
          setProfileSummary(res.data.profile_summary)

          setChartData(res.data.weekly_api_usage);

          console.log("wwwww999",res.data.weekly_api_usage)
          setApiDatauseage(res.data.purchase_log);
          setLoading(false);
          setinnerloader(false)
        })
        .catch((error) => {
          console.error("Error fetching overview data", error);
          setLoading(false);
          setinnerloader(false)
        });
    }
  }, [isDatepicker, loaderCondition]);

  const calculateDaysBetweenDates = (
    startDate: string,
    endDate: string
  ): number => {
    const dateFormat = "yyyy-MM-dd";
    const start = parse(startDate, dateFormat, new Date());
    const end = parse(endDate, dateFormat, new Date());
    return differenceInDays(end, start);
  };

  // Function to check if the selected range is within the current week
  const isWithinCurrentWeek = (startDate: string, endDate: string): boolean => {
    const dateFormat = "yyyy-MM-dd";
    const today = new Date();

    // Calculate the start of the current week (last 7 days)
    const startOfWeek = moment().subtract(6, "days").startOf("day").toDate();
    const endOfWeek = today;

    // Parse the selected dates
    const start = parse(startDate, dateFormat, new Date());
    const end = parse(endDate, dateFormat, new Date());

    // Check if the selected range is within the current week
    return (
      isWithinInterval(start, { start: startOfWeek, end: endOfWeek }) &&
      isWithinInterval(end, { start: startOfWeek, end: endOfWeek })
    );
  };

  const download = () => {
    const param = {
      from_date: isDatepicker?.startDate,
      to_date: isDatepicker?.endDate,
      csv_download: true,
    };
    Getoverview(param)
      .then((res) => {
        setLoading(false);
        const downloadUrl = res.data.download_url;
        const anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = "";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        notify("CSV downloaded successfully!", "success", 3000);
        setButtonloader(false);
      })
      .catch((error) => {
        console.error("Error fetching overview data", error);
        setLoading(false);
        setButtonloader(false);
      });
  };

  // if (loading) {
  //   return <Loader />;
  // }
console.log("wwwww",chartData)
  return (
    <div className="container" style={{ overflow: isScroll ? "hidden" : "" }}>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: loaderHeight,
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className={`${styles.stickyheader}`}>
            <OverViewTitle />
          </div>
          

          <div className={styles.makecol}>
            <div className={styles.colfirst} >
              <OverViewDetails
                callRequest={callRequest}
                remainingCredits={remainingCredits}
                creditsConsumed={creditsConsumed}
                OverallErrorRate={OverallErrorRate}
              />
            </div>

            <div className={styles.colsecond} >
              {/* <AllApiList data={purchase}/> */}
              <ApiPurchaseLog data={purchase} />
            </div>
          </div>
         
          {chartData && ResumeParser &&AiMatchingAnalysis &&ComparativeAnalysis && AiInterview&&ProfileSummary&&AiJdAssistance&&JdParser&&(
            <ApiUseage
              data={chartData}
              ResumeParser={ResumeParser}
              AiMatchingAnalysis={AiMatchingAnalysis}
              ComparativeAnalysis={ComparativeAnalysis}
              AiInterview={AiInterview}
              ProfileSummary={ProfileSummary}
              AiJdAssistance={AiJdAssistance}
              JdParser={JdParser}
              setDatepicker={setDatepicker}
              ovralldays={ovralldays}
              download={download}
              setLoading={setLoading}
              setisCurrentWeek={setisCurrentWeek}
              isCurrentWeek={isCurrentWeek}
              isDatepicker={isDatepicker}
              setButtonloader={setButtonloader}
              isButtonloader={isButtonloader}
              handleShow={handleShow}
              handleHide={handleHide}
              setinnerloader={setinnerloader}
              innerloader={innerloader}
            />
          )}

          {/* <div className="row my-3">
            <div className="col-lg-7 col-md-7 col-sm-12 mb-3 pe-md-0 pe-lg-0">
              <ApiPurchaseLog data={apiDatauseage} />
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12">
              <ErrorRate
                data={errorRateData}
                ovralldays={ovralldays}
                isCurrentWeek={isCurrentWeek}
                isDatepicker={isDatepicker}
              />
            </div>
          </div> */}
        </>
      )}
    </div>
  );
}
