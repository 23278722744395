import React, { useState } from "react";
import moment from "moment";
import styles from "./notification.module.css";
import { useNavigate } from "react-router-dom";
import { Notificationapi } from "../../api";

type Props = {
  apiData: any;
  setDropdown: any;
  setApivalues: any;
  setPageLoader: any;
};

const Notification = ({
  apiData,
  setDropdown,
  setApivalues,
  setPageLoader,
}: Props) => {
  const [onlyReadMsg, setonlyReadMsg] = useState(false);

  const navigateTo = useNavigate();

  const handleUnreadMessage = () => {
    setonlyReadMsg(!onlyReadMsg);
  };

  const handleNavigation = () => {
    navigateTo("/api-products");
    setDropdown({
      help: false,
      notification: false,
      userMenu: false,
    });
  };

  const handleNotificationView = (messageID?: any) => {
    const myID = [messageID];
    handleNavigation();
    Notificationapi(false, JSON.stringify(myID)).then((res) => {
      setApivalues(res.data.data);
    });
  };

  const filterUnread = (notifications: any[] = []) => {
    return onlyReadMsg
      ? notifications?.filter((notification: any) => !notification?.is_read)
      : notifications;
  };

  const unreadNotificationIds = [
    ...filterUnread(apiData?.today),
    ...filterUnread(apiData?.yesterday),
    ...filterUnread(apiData?.others),
  ]
    ?.filter((notification: any) => !notification?.is_read)
    ?.map((message: any) => message.notification);

  const handleMarkRead = () => {
    setPageLoader(true);
    Notificationapi(true, JSON.stringify(unreadNotificationIds)).then((res) => {
      setApivalues(res.data.data);
      setPageLoader(false);
    });
  };

  const showMessageScreen =
    (filterUnread(apiData?.today)?.length === 0 ||
      filterUnread(apiData?.yesterday)?.length === 0 ||
      filterUnread(apiData?.others)?.length === 0) &&
    filterUnread(apiData?.today)?.length === 0 &&
    filterUnread(apiData?.yesterday)?.length === 0 &&
    filterUnread(apiData?.others)?.length === 0;

  return (
    <>
      <div className={`container-sm ${styles.notifiBlock}`}>
        <div className="d-flex justify-content-between border-bottom pb-2 mb-2">
          <div className="d-flex">
            <span className={styles.notificationTitle}>Notification</span>
          </div>
          <div className="d-flex">
            <div className="d-flex">
              <span className={styles.switchText}>Only show unread</span>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ marginLeft: 10 }}
            >
              <div className={`form-check form-switch ${styles.switchComp}`}>
                <input
                  className={`form-check-input ${styles.inputSwitch}`}
                  style={{
                    backgroundColor: onlyReadMsg ? "#581845" : "",
                  }}
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  checked={onlyReadMsg}
                  onClick={() => {
                    handleUnreadMessage();
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.scrollbarContainer}>
          {apiData?.total !== 0 && (
            <>
              <div>
                <>
                  {(filterUnread(apiData?.today)?.length > 0 ||
                    filterUnread(apiData?.yesterday)?.length > 0 ||
                    filterUnread(apiData?.others)?.length > 0) && (
                    <div className="position-absolute" style={{ right: "5px" }}>
                      <span
                        onClick={() => handleMarkRead()}
                        className={
                          unreadNotificationIds.length === 0
                            ? styles.markasReadDisable
                            : styles.markasRead
                        }
                      >
                        Mark all as read
                      </span>
                    </div>
                  )}
                </>
                {filterUnread(apiData?.today) &&
                  filterUnread(apiData?.today)?.length !== 0 && (
                    <div className={styles.messageToday}>
                      <div className="d-flex flex-row justify-content-between mb-2">
                        <span className={styles.periodText}>Today</span>
                      </div>
                      {filterUnread(apiData?.today).map((notifi: any) => (
                        <div
                          key={notifi.notification}
                          className={`${styles.msgBlock}`}
                        >
                          <div
                            onClick={() =>
                              handleNotificationView(notifi.notification)
                            }
                            className={`d-flex flex-row justify-content-between ${styles.message}`}
                          >
                            <span className={styles.msgDesc}>
                              {notifi.message}
                            </span>
                            <div
                              className={`d-flex justify-content-between ${styles.timeBlock}`}
                            >
                              <span className={styles.msgTime}>
                                {moment(notifi.timestamp).fromNow()}
                              </span>
                              {notifi.is_read === false ? (
                                <div className={`${styles.statusDiv}`}>
                                  <div className={styles.msgStatus} />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
              </div>

              <div>
                {filterUnread(apiData?.yesterday) &&
                  filterUnread(apiData?.yesterday)?.length !== 0 && (
                    <div className={styles.messageYesterday}>
                      <div className="d-flex flex-row justify-content-between mb-2">
                        <span className={styles.periodText}>Yesterday</span>
                      </div>
                      {filterUnread(apiData?.yesterday).map((notifi: any) => (
                        <div
                          key={notifi.notification}
                          className={`${styles.msgBlock}`}
                        >
                          <div
                            onClick={() =>
                              handleNotificationView(notifi.notification)
                            }
                            className={`d-flex flex-row justify-content-between ${styles.message}`}
                          >
                            <span className={styles.msgDesc}>
                              {notifi.message}
                            </span>
                            <div
                              className={`d-flex justify-content-between ${styles.timeBlock}`}
                            >
                              <span className={styles.msgTime}>
                                {moment(notifi.timestamp).fromNow()}
                              </span>
                              {notifi.is_read === false ? (
                                <div className={`${styles.statusDiv}`}>
                                  <div className={styles.msgStatus} />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
              </div>

              <div>
                {filterUnread(apiData?.others) &&
                  filterUnread(apiData?.others)?.length !== 0 && (
                    <div>
                      <div className="d-flex flex-row justify-content-between mb-2">
                        <span className={styles.periodText}>Older</span>
                      </div>

                      {filterUnread(apiData?.others).map((notifi: any) => (
                        <div
                          key={notifi.notification}
                          className={`${styles.msgBlock}`}
                        >
                          <div
                            onClick={() =>
                              handleNotificationView(notifi.notification)
                            }
                            className={`d-flex flex-row justify-content-between ${styles.message}`}
                          >
                            <span className={styles.msgDesc}>
                              {notifi.message}
                            </span>
                            <div
                              className={`d-flex justify-content-between ${styles.timeBlock}`}
                            >
                              <span className={styles.msgTime}>
                                {moment(notifi.timestamp).fromNow()}
                              </span>
                              {notifi.is_read === false ? (
                                <div className={`${styles.statusDiv}`}>
                                  <div className={styles.msgStatus} />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </>
          )}

          {showMessageScreen && (
            <>
              <div
                className={`d-flex flex-column align-items-center ${styles.noMsgBlock}`}
              >
                <span className={styles.noMsgText}>You're all up to date</span>
                <span className={styles.noMsgDesc}>
                  There are no new notification at the moment
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Notification;
