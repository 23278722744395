import SvgAuthenticate from "../../icons/SvgAuthenticate";
// import AuthenticationForm from "./AuthenticationForm";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import SvgCopy from "../../icons/SvgCopy";
import SvgEyeClose from "../../icons/SvgEyeClose";
import SvgEyeOpen from "../../icons/SvgEyeOpen";
import SvgCopySign from "../../icons/SvgCopySign";
import CustomTooltip from "../../uikit/CustomTooltip/CustomTooltip";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store/store";
import {
  fetchAuthenticationData,
  resetSecretKey,
} from "../../redux/reducers/AuthenticationSlice";
import { notify } from "../../uikit/toast";
import styles from "../Authentication/Authentication.module.css";
import Loader from "../../uikit/Loader";

interface FormValues {
  organisationId: string;
  url: string;
  secretKey: string;
}

export default function Authentication() {
  const [isSecretVisible, setIsSecretVisible] = useState(false);

  const [copyOrgidTooltip, setCopyOrgidTooltip] = useState<string | JSX.Element>(
    "Click to Copy"
  );
  const [copyUrlTooltip, setCopyUrlTooltip] = useState<string | JSX.Element>(
    "Click to Copy"
  );
  const [copyKeyTooltip, setCopyKeyTooltip] = useState<string | JSX.Element>(
    "Click to Copy"
  );
  const [loader, setloader] = useState<boolean>(false);
  // const [initialload, setinitialload] = useState<boolean>(true);

  const dispatch = useDispatch<AppDispatch>();
  const { data, isLoading } = useSelector(
    (state: RootState) => state.authentication
  );

  // console.log("====>",data)
  const toggleSecretVisibility = () => {
    setIsSecretVisible(!isSecretVisible);
  };

  const initialValues: FormValues = {
    organisationId: data?.org_id || "",
    url: data?.access_url || "",
    secretKey: data?.response || "",
  };

  const onSubmit = async (values: FormValues) => {
    setloader(true);
    try {
      dispatch(resetSecretKey({ generate: true })).then((res: any) => {
        if (res.payload) {
          notify("Secret key reset successfully!", "success", 3000);
        } else if (res.error) {
          notify(res.error, "error", 3000);
        }
        formik.setErrors({
          secretKey: res.payload?.message,
        });
        setloader(false);
      });
    } catch (error) {
      console.log("An error occured");
      setloader(false);
    }
  };

  // console.log('loader', loader)

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit,
  });

  const copyToClipboardOrgid = (copyData: string) => {
    if (copyData) {
      navigator.clipboard.writeText(copyData).then(() => {
        setCopyOrgidTooltip(
          <>
            <SvgCopySign /> Copied
          </>
        );
        setTimeout(() => {
          setCopyOrgidTooltip("Click to Copy");
        }, 1000);
      });
    } else {
      setCopyOrgidTooltip("Nothing to copy!");
    }
  };

  const copyToClipboardUrl = (copyData: string) => {
    if (copyData) {
      navigator.clipboard.writeText(copyData).then(() => {
        setCopyUrlTooltip(
          <>
            <SvgCopySign /> Copied
          </>
        );
        setTimeout(() => {
          setCopyUrlTooltip("Click to Copy");
        }, 1000);
      });
    } else {
      setCopyUrlTooltip("Nothing to copy!");
    }
  };

  const copyToClipboardKey = (copyData: string) => {
    if (copyData) {
      navigator.clipboard.writeText(copyData).then(() => {
        setCopyKeyTooltip(
          <>
            <SvgCopySign /> Copied
          </>
        );
        setTimeout(() => {
          setCopyKeyTooltip("Click to Copy");
        }, 1000);
      });
    } else {
      setCopyKeyTooltip("Nothing to copy!");
    }
  };

  // useEffect(() => {
  //   // if (initialload) {
  //     dispatch(fetchAuthenticationData()).then(() => {
  //       // setinitialload(false);
  //     });
  //   // }
  // }, [dispatch]);

  useEffect(() => {
    if (!data?.org_id || !data?.response || !data?.access_url) {
      dispatch(fetchAuthenticationData());
    }
  }, [dispatch, data]);

  useEffect(() => {
    if (data) {
      formik.setValues({
        organisationId: data.org_id,
        url: data.access_url,
        secretKey: data.response || "",
      });
    }
  }, [data]);

  if (isLoading) return <Loader />;
  return (
    <>
      <div className="container p-3 mt-1">
        <div className="d-flex mb-3 align-items-center">
          <SvgAuthenticate height={20} width={20} />
          <span
            className="d-flex align-items-center ms-2"
            style={{
              fontSize: "20px",
              fontWeight: "700",
              paddingBottom: "3px"
            }}
          >
            Authentication
          </span>
        </div>
        <div className="form-container me-5">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="col-md-5">
                <div className="mb-3">
                  <label
                    htmlFor="organisationId"
                    className="form-label fw-bold "
                    style={{ fontSize: "13px" }}
                  >
                    Organisation ID
                  </label>
                  <div
                    className="position-relative"
                    style={{ maxWidth: "404px" }}
                  >
                    <input
                      style={{
                        height: "44px",
                        maxWidth: "405px",
                        fontSize: "13px",
                        color:'#581845'
                      }}
                      type="text"
                      className="form-control rounded-1"
                      id="organisationId"
                      name="organisationId"
                      disabled
                      readOnly
                      value={formik.values.organisationId}
                    />
                    <span
                      className="position-absolute top-17 end-0 me-2"
                      onClick={() =>
                        copyToClipboardOrgid(formik.values.organisationId)
                      }
                      style={{
                        cursor: "pointer",
                        right: "10px",
                        zIndex: 1,
                        top: "20%",
                      }}
                    >
                      <CustomTooltip title={copyOrgidTooltip}>
                        <SvgCopy />
                      </CustomTooltip>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="col-md-5">
                <div className="mb-3">
                  <label
                    htmlFor="url"
                    className="form-label fw-bold "
                    style={{ fontSize: "13px" }}
                  >
                    URL
                  </label>
                  <div
                    className="position-relative"
                    style={{ maxWidth: "404px" }}
                  >
                    <input
                      style={{
                        height: "44px",
                        maxWidth: "405px",
                        pointerEvents: "none", //changes made
                        fontSize: "13px",
                        whiteSpace: "wrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        paddingRight: "25px",
                        color:"#581845"
                      }}
                      type="text"
                      className="form-control rounded-1"
                      id="url"
                      name="url"
                      disabled
                      readOnly
                      value={formik.values.url}
                    />
                    <span
                      className="position-absolute top-17 end-0  me-2"
                      onClick={() => copyToClipboardUrl(formik.values.url)}
                      style={{
                        cursor: "pointer",
                        right: "10px",
                        zIndex: 1,
                        top: "20%",
                      }}
                    >
                      <CustomTooltip title={copyUrlTooltip}>
                        <SvgCopy />
                      </CustomTooltip>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="col-md-5">
                <div className="mb-3">
                  <label
                    htmlFor="secretKey"
                    className="form-label fw-bold "
                    style={{ fontSize: "13px" }}
                  >
                    Secret Key
                  </label>

                  <div
                    className="position-relative"
                    style={{ maxWidth: "404px" }}
                  >
                    <input
                      style={{
                        height: "44px",
                        maxWidth: "405px",
                        fontSize: "13px",
                      }}
                      type={isSecretVisible ? "text" : "password"}
                      className="form-control rounded-1"
                      id="secretKey"
                      readOnly
                      {...formik.getFieldProps("secretKey")}
                    />
                    {formik.touched.secretKey && formik.errors.secretKey ? (
                      <p className="text-danger">{formik.errors.secretKey}</p>
                    ) : null}

                    <span
                      className="position-absolute top-17 end-0 "
                      onClick={toggleSecretVisibility}
                      style={{
                        cursor: "pointer",
                        right: "35px",
                        zIndex: 1,
                        marginRight: "38px",
                        top: "20%",
                      }}
                    >
                      <i>
                        {isSecretVisible ? <SvgEyeClose /> : <SvgEyeOpen />}
                      </i>
                    </span>

                    <span
                      className="position-absolute top-60"
                      style={{
                        height: "19px",
                        borderLeft: "1.5px solid #ccc",
                        padding: "7px 3px",
                        right: "23px",
                        zIndex: 1,
                        top: "32%",
                      }}
                    ></span>
                    <span
                      className="position-absolute top-17 end-0  me-2"
                      onClick={() => copyToClipboardKey(formik.values.secretKey)}
                      style={{
                        cursor: "pointer",
                        right: "10px",
                        zIndex: 1,
                        top: "20%",
                      }}
                    >
                      <CustomTooltip title={copyKeyTooltip}>
                        <SvgCopy />
                      </CustomTooltip>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {loader ? (
                <div className="d-flex justify-content">
                  <button
                    className={`btn ${styles.authenticationbtnloder}`}
                    type="submit"
                    style={{ width: "122.21px", height: "33.6px" }}
                  >
                    <span
                      className={`spinner-border spinner-border-sm ${styles.loderboder}`}
                      role="status"
                      style={{ width: "15px", height: "15px" }}
                    ></span>
                  </button>
                </div>
              ) : (
                <div className="d-flex justify-content">
                  <button
                    className={`btn ${styles.authenticationbtn}`}
                    type="submit"
                    // style={{ paddingRight: "30px", paddingLeft:"30px" }}
                  >
                    Reset Secret Key
                  </button>
                </div>
              )}
            </div>

            {/* <div className="d-flex justify-content">
                <Button
                  name="Reset Secret Key"
                  variant="primary"
                  type="submit"
                  width="fit-content"
                  padding="0px 20px"
                />
              </div> */}
          </form>
        </div>
      </div>
    </>
  );
}
