import React from 'react';
import { 
  Chart as ChartJS, 
  LineController, 
  LineElement, 
  CategoryScale, 
  LinearScale, 
  PointElement, 
  Title, 
  Tooltip, 
  Legend 
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { Getoverview } from "../../api";
import SvgNoReport from '../../icons/SvgNoReport';

// Registering all necessary Chart.js components, including LineController
ChartJS.register(
  LineController,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

interface ApiUsageData {
  lable: string[];
  day: number[];
  api_cals: number[];
  error_rate: number[];
  credits_log: number[];
}

interface MixedChartProps {
  data: ApiUsageData;
  AiMatchingAnalysis: ApiUsageData;
  ResumeParser: ApiUsageData;
  AiInterview: ApiUsageData;
  ProfileSummary: ApiUsageData;
  AiJdAssistance: ApiUsageData;
  JdParser: ApiUsageData;
}
interface Dataset {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
  tension: number;
  pointRadius: number;
  pointHoverRadius: number;
  fill: boolean;
  errorrate: number[];
  credits: number[];
}

const ApiMixedChart: React.FC<MixedChartProps> = ({ data,AiMatchingAnalysis,ResumeParser,AiInterview,ProfileSummary,AiJdAssistance,JdParser }) => {


  // No Data Condition
  if (
    (ResumeParser && ResumeParser.day.length === 0 && ResumeParser.api_cals.length === 0) &&
    (AiMatchingAnalysis && AiMatchingAnalysis.api_cals.length === 0) &&
    (data && data.api_cals.length === 0) &&
    (AiInterview && AiInterview.api_cals.length === 0) &&
    (ProfileSummary && ProfileSummary.api_cals.length === 0) &&
    (AiJdAssistance && AiJdAssistance.api_cals.length === 0) &&
    (JdParser && JdParser.api_cals.length === 0)
  ) {
    return (
      <div
        className="col-12 d-flex justify-content-center align-items-center flex-column"
        style={{ height: "300px" }}
      >
        <div style={{ padding: '0 0 10px 0' }}>
          <SvgNoReport />
        </div>
        <div style={{ color: '#999FAC', fontSize: '13px' }}>
          Looks like there are no report details available.
        </div>
      </div>
    );
  }
  const datasets: Dataset[] = []; 
// Check if data.api_calls has any entries and push the dataset if it does
if (data.api_cals.length > 0) {
  datasets.push({
      label: 'Comparative Analysis',
      data: data.api_cals,
      borderColor: 'RGB(124, 181, 236)', // Bright Blue
      backgroundColor: 'RGB(124, 181, 236)',
      tension: 0.1,
      pointRadius: 4,
      pointHoverRadius: 4,
      fill: true,
      errorrate:data.error_rate,
      credits:data.credits_log
  });
}

// Check if newdata.api_calls has any entries and push the dataset if it does
if (AiMatchingAnalysis.api_cals.length > 0) {
    datasets.push({
      label: 'AI Matching Analysis',
      data: AiMatchingAnalysis.api_cals,
      borderColor: 'RGB(124, 208, 124)', // Medium Gray
      backgroundColor: 'RGB(124, 208, 124)', // Medium Gray with transparency
      tension: 0.1,
      pointRadius: 4,
      pointHoverRadius: 4,
      fill: true,
      errorrate: AiMatchingAnalysis.error_rate,
      credits: AiMatchingAnalysis.credits_log
    });
  }
  
  

// Repeat the same for newdata1, newdata2, newdata3, newdata4, and newdata5
if (AiInterview.api_cals.length > 0) {
  datasets.push({
      label: 'AI Interview Question Generation',
      data: AiInterview.api_cals,
      borderColor: 'RGB(255, 235, 104)', // Teal
      backgroundColor: 'RGB(255, 235, 104)',
      tension: 0.1,
      pointRadius: 4,
      pointHoverRadius: 4,
      fill: true,
      errorrate:AiInterview.error_rate,
      credits:AiInterview.credits_log
  });
}

if (ProfileSummary.api_cals.length > 0) {
  datasets.push({
      label: 'Profile Summary',
      data: ProfileSummary.api_cals,
      borderColor: 'RGB(236, 124, 124)', // Soft Yellow
      backgroundColor: 'RGB(236, 124, 124)',
      tension: 0.1,
      pointRadius: 4,
      pointHoverRadius: 4,
      fill: true,
      errorrate:ProfileSummary.error_rate,
      credits:ProfileSummary.credits_log
  });
}

if (AiJdAssistance.api_cals.length > 0) {
    datasets.push({
      label: 'AI JD Assistance',
      data: AiJdAssistance.api_cals,
      borderColor: 'RGB(180, 124, 180)', // Medium Red (Tomato)
      backgroundColor: 'RGB(180, 124, 180)', // Medium Red with transparency
      tension: 0.1,
      pointRadius: 4,
      pointHoverRadius: 4,
      fill: true,
      errorrate: AiJdAssistance.error_rate,
      credits: AiJdAssistance.credits_log
    });
  }
  

if (JdParser.api_cals.length > 0) {
  datasets.push({
      label: 'JD Parser',
      data: JdParser.api_cals,
      borderColor: 'RGB(255, 174, 104)', // Lavender
      backgroundColor: 'RGB(255, 174, 104)',
      tension: 0.1,
      pointRadius: 4,
      pointHoverRadius: 4,
      fill: true,
      errorrate:JdParser.error_rate,
      credits:JdParser.credits_log
  });
}

if (ResumeParser.api_cals.length > 0) {
  datasets.push({
      label: 'Resume Parser',
      data: ResumeParser.api_cals,
      borderColor: 'RGB(181, 181, 181)', // Orange
      backgroundColor: 'RGB(181, 181, 181)',
      tension: 0.1,
      pointRadius: 4,
      pointHoverRadius: 4,
      fill: true,
      errorrate:ResumeParser.error_rate,
      credits:ResumeParser.credits_log
  });
}

  // Chart Data
  const chartData = {
    labels: 
    data.lable.length !== 0 ? data.lable : 
    ResumeParser.lable.length !== 0 ? ResumeParser.lable : 
    AiMatchingAnalysis.lable.length !== 0 ? AiMatchingAnalysis.lable : 
    AiInterview.lable.length !== 0 ? AiInterview.lable : 
    ProfileSummary.lable.length !== 0 ? ProfileSummary.lable : 
    AiJdAssistance.lable.length !== 0 ? AiJdAssistance.lable : 
    JdParser.lable.length !== 0 ? JdParser.lable : [],
    datasets: datasets
  };


  // Chart Options
  const options = {
    
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: 'Weekly API Usage',
        color: '#333',
        padding: {
          top: 20,
          bottom: 20,
        },
        font: {
          size: 14,
          weight: 400,
          family: 'Roboto, sans-serif',
        },
      },
      legend: {
        display: true, // Show or hide the legend
       
        font: {
            size: 14, // Font size for legend labels
            weight: 'bold', // Font weight (e.g., 'normal', 'bold')
            family: 'Roboto, sans-serif',
            lineHeight: 2, 
          },
        labels: {
            color: '#666', // Text color for legend labels
            boxWidth: 20, // Width of the legend color box
            boxHeight: 10, // Height of the legend color box
            padding: 10, // Padding between legend items
            usePointStyle: true, // Use point style (circle instead of square)
            pointStyle: 'circle', // Shape of the point style ('circle', 'rect', etc.)
          },
    
      },
      
      tooltip: {
        padding: 10,
        bodySpacing: 5,
        callbacks: {
            title: (tooltipItems: any) => {
                let label = '';  // Initialize an empty string for the label
          
                // Check conditions for each dataset to determine which label to use
                if (data.lable.length !== 0) {
                  label = data.lable[tooltipItems[0]?.dataIndex ?? 0];
                } else if (AiMatchingAnalysis.lable.length !== 0) {
                  label = AiMatchingAnalysis.lable[tooltipItems[0]?.dataIndex ?? 0];
                } else if (ResumeParser.lable.length !== 0) {
                  label = ResumeParser.lable[tooltipItems[0]?.dataIndex ?? 0];
                } else if (AiInterview.lable.length !== 0) {
                  label = AiInterview.lable[tooltipItems[0]?.dataIndex ?? 0];
                } else if (ProfileSummary.lable.length !== 0) {
                  label = ProfileSummary.lable[tooltipItems[0]?.dataIndex ?? 0];
                } else if (AiJdAssistance.lable.length !== 0) {
                  label = AiJdAssistance.lable[tooltipItems[0]?.dataIndex ?? 0];
                } else if (JdParser.lable.length !== 0) {
                  label = JdParser.lable[tooltipItems[0]?.dataIndex ?? 0];
                }
          
                return `Day: ${label}`;
              },
          label: (tooltipItem: any) => {
            const chart = tooltipItem.chart;
            const label=tooltipItem.dataset.label ?? ''
            const apiCalls = tooltipItem.dataset.data[tooltipItem.dataIndex];
            const credits = tooltipItem.dataset.credits[tooltipItem.dataIndex];
            const errorRate = tooltipItem.dataset.errorrate[tooltipItem.dataIndex];
         
            const count = datasets.reduce((acc, dataset,index) => {
              // return acc + (dataset.data[tooltipItem.dataIndex] === apiCalls ? 1 : 0);
              return acc + (chart.isDatasetVisible(index) && dataset.data[tooltipItem.dataIndex] === apiCalls ? 1 : 0);
            }, 0);
            
            if (count > 4) {
              // Display all information in a single row
              return [
                ` ${label}`,  // Show the label
                `Number of API calls: ${apiCalls} | Credits used: ${credits} | Error Rate: ${errorRate}`, // Row format
              ];
            } else {
              // Display all information in column format (each item in a new line)
              return [
                ` ${label}`,
                `Number of API calls: ${apiCalls}`,
                `Credits used: ${credits}`,
                `Error Rate: ${errorRate}`,
              ];
            }
          },
          
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'transparent',
        borderWidth: 0,
        animation: {
          duration: 0,
        },
        titleFont: {
          family: 'Roboto, sans-serif',
        },
        bodyFont: {
          family: 'Roboto, sans-serif',
        },
        maxWidth: 200, // Adjust based on your needs
      // Set a minimum height to prevent it from being too short
      minHeight: 50, // Adjust based on your needs
      },
    },
    scales: {
        x: {
          title: {
            display: true,
            text: 'Days',
            font: {
              size: 13,
              family: 'Roboto, sans-serif',
            },
          },
          ticks: {
            maxTicksLimit: 10,  
            autoSkip: true,    // Ensures the ticks appear every 5 units on the X-axis
          },
          grid: {
            color: 'transparent', // Hide grid lines
          },
        },
        y: {
          title: {
            display: true,
            text: 'API Calls',
            font: {
              size: 13,
              family: 'Roboto, sans-serif',
            },
          },
      
            beginAtZero: true,  // Ensures Y-axis starts at 0
       
        },
      },
      
      
  };

  return <Chart type="line" data={chartData} options={options} height={100} />;
};

export default ApiMixedChart;
