import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "../Signup/signup.module.css";
import SvgZita from "../../icons/SvgZita";
import { Link, useNavigate } from "react-router-dom";
import { Signupapi } from "../../api";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CustomTooltip from "../../uikit/CustomTooltip/CustomTooltip";
import { verifyToken } from "../../configuration";
import Loader from "../../uikit/Loader";
import ErrorMessage from "../../uikit/Errormessage/Errormessage";
import { notify } from "../..//uikit/toast";
import { domainValidation, mailformat } from "./ConstValue";


interface SignUpPayLoad {
  first_name: "";
  last_name: "";
  company_name: "";
  contact_no: "";
  country: "";
  email: string;
  password1: "";
  password2: "";
  terms_and_conditions: boolean;
  terms_and_conditions1: string;
  eye1: boolean;
  eye2: boolean;
  isdomains: boolean;
}
const SignupScreen = () => {
  const version = process.env.REACT_APP_VERSION;
  const [loader, setloader] = useState(false);
  const [overallloader, setoverallloader] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    setoverallloader(true);
    const handleVerifyTokens = async () => {
      const accessToken = sessionStorage.getItem("access_token");
      const idToken = sessionStorage.getItem("id_token");

      if (accessToken && idToken) {
        try {
          const accessTokenPayload = await verifyToken(accessToken, "access");
          const idTokenPayload = await verifyToken(idToken, "id");
          if (accessTokenPayload && idTokenPayload) {
            navigate("/overview");
            setoverallloader(false);
          } else {
            navigate("/sign-up");
            setoverallloader(false);
          }
        } catch (err) {
          console.error("Error verifying tokens:", err);
          navigate("/sign-up");
          setoverallloader(false);
        }
      } else {
        console.error("No access token or ID token found in session storage.");
        navigate("/sign-up");
        setoverallloader(false);
      }
    };
    handleVerifyTokens();
  }, []);

  const initial: SignUpPayLoad = {
    first_name: "",
    last_name: "",
    company_name: "",
    contact_no: "",
    country: "",
    email: "",
    password1: "",
    password2: "",
    eye1: false,
    eye2: false,
    terms_and_conditions: false,
    terms_and_conditions1: "",
    isdomains: true // Adjust the validation if the domain is denied.
  };

  const handleValid = (values: SignUpPayLoad) => {
    const errors: Partial<SignUpPayLoad> = {};
    if (
      !domainValidation.test(values.email.toLowerCase()) &&
      mailformat.test(values.email.toLowerCase()) &&
      values.isdomains === true) {
      errors.email = 'Enter a valid email ID with domain';
    }
    if (
      values.first_name.length !== 0 &&
      values.last_name.length !== 0 &&
      values.company_name.length !== 0 &&
      values.contact_no.length !== 0 &&
      values.email.length !== 0 &&
      values.password1.length !== 0 &&
      values.password2.length !== 0 &&
      values.password1 === values.password2 &&
      values.terms_and_conditions === false
    ) {
      errors.terms_and_conditions1 =
        "You must agree with the Terms and Conditions.";
    }
    return errors;
  };
  const hanldeSubmit = () => {
    setloader(true);
    Signupapi(
      formik.values.first_name,
      formik.values.last_name,
      formik.values.password2,
      formik.values.contact_no,
      formik.values.company_name,
      formik.values.email
    )
      .then((res) => {
        sessionStorage.setItem("ver-email", formik.values.email);
        if (res?.data?.success) {
          notify(res?.data?.data?.message, "success", 3000);
          setTimeout(() => {
            navigate("/code-verification");
            setloader(false);
          }, 100);
        }
      })
      .catch((error) => {
        setloader(false);
        console.log("err", error?.response?.data?.data?.message);
        if (error?.response?.data?.data?.message === "Email already exist.") {
          formik.errors.email = error?.response?.data?.data?.message;
        }
        // notify(error?.response?.data?.data?.message, "error", 3000);
      });
  };
  const SignupSchema = Yup.object().shape({
    first_name: Yup.string()
      .trim("Space is not a character.")
      .max(20, "Cannot exceed 20 characters.")
      .required("First name is required."),
    last_name: Yup.string()
      .trim("Space is not a character.")
      .max(20, "Cannot exceed 20 characters.")
      .required("Last name is required."),
    company_name: Yup.string()
      .trim("Space is not a character.")
      .max(50, "Company name cannot exceed 50 characters.")
      .required("Company name is required."),
    contact_no: Yup.string()
      .matches(/^\+?[1-9]\d{7,11}$/, "Please enter a valid phone number.")
      .required("Phone number is required."),
    email: Yup.string()
      .trim("Space is not a character.")
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        "Please enter a valid email address."
      )
      .max(50, "Email address cannot exceed 50 characters.")
      .required("Email address is required."),
    password1: Yup.string()
      .trim("Space is not a character.")
      .min(8, "Password must be 8+ characters.")
      .matches(/[A-Z]/, "Must include 1 uppercase letter.")
      .matches(/\d/, "Must include 1 number.")
      .matches(/[!@#$%^&*]/, "Must include 1 special character.")
      .max(20, "Password cannot exceed 20 characters.")
      .required("Password is required."),
    password2: Yup.string()
      .trim("Space is not a character")
      .oneOf([Yup.ref("password1")], "Passwords do not match.")
      .required("Confirm password is required."),
  });

  const formik = useFormik({
    initialValues: initial,
    onSubmit: hanldeSubmit,
    validate: (value) => handleValid(value),
    validationSchema: SignupSchema,
  });

  //   const validateEmail = (value: any) => {
  //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     return emailRegex.test(value);
  //   };
  return (
    <>
      {!overallloader ? (
        <div
          className={`container-fluid ${styles.bgimage}`}
          style={{
            backgroundImage: `url(/Images/loginbgimage.png)`,
            fontSize: "13px",
          }}
        >
          <div
            className="d-flex justify-content-center"
            style={{ minHeight: "80vh" }}
          >
            <div className={`text-center`} style={{ marginTop: "3%" }}>
              <SvgZita width={150} height={80} />
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <h6 style={{ fontWeight: 600, fontSize: "20px" }}>
                  Sign up to begin your API service experience.
                </h6>
              </div>
              <div style={{ marginTop: "25px" }}>
                <div
                  className={`row d-flex justify-space-between col-md-12  col-12 ${styles.mobile}`}
                >
                  <div className="col-md-6  col-12">
                    <div
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "13px",
                          marginBottom: "0",
                          fontWeight: "600",
                        }}
                      >
                        First Name
                      </p>
                      <input
                        style={{
                          height: "30px",
                          width: "100%",
                        }}
                        type="text"
                        className={` ${styles.textbox}`}
                        id="first_name"
                        name="first_name"
                        value={formik.values.first_name}
                        onChange={(e) => {
                          formik.setFieldValue("first_name", e.target.value);
                        }}
                      />
                      <div
                        style={{
                          marginTop:
                            formik.errors &&
                              typeof formik.errors["first_name"] === "string" &&
                              formik.touched["first_name"]
                              ? "3px"
                              : "0px",
                          marginBottom:
                            formik.errors &&
                              typeof formik.errors["first_name"] === "string" &&
                              formik.touched["first_name"]
                              ? "6px"
                              : "27px",
                        }}
                      >
                        <ErrorMessage
                          name={"first_name"}
                          errors={formik.errors}
                          touched={formik.touched}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6  col-12">
                    <div
                      // className={`${styles.mobpad}`}
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "13px",
                          marginBottom: "0",
                          fontWeight: "600",
                        }}
                      >
                        Last Name
                      </p>
                      <input
                        style={{
                          height: "30px",
                          width: "100%",
                          paddingRight: "40px",
                        }}
                        type="text"
                        className={`${styles.textbox}`}
                        id="last_name"
                        name="last_name"
                        value={formik.values.last_name}
                        onChange={(e) => {
                          formik.setFieldValue("last_name", e.target.value);
                        }}
                      />
                      <div
                        style={{
                          marginTop:
                            formik.errors &&
                              typeof formik.errors["last_name"] === "string" &&
                              formik.touched["last_name"]
                              ? "3px"
                              : "0px",
                          marginBottom:
                            formik.errors &&
                              typeof formik.errors["last_name"] === "string" &&
                              formik.touched["last_name"]
                              ? "6px"
                              : "27px",
                        }}
                      >
                        <ErrorMessage
                          name={"last_name"}
                          errors={formik.errors}
                          touched={formik.touched}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`row d-flex justify-space-between col-md-12  col-12 ${styles.mobile}`}
                // style={{ marginTop: "25px" }}
                >
                  <div className="col-md-6  col-12">
                    <div style={{ textAlign: "left" }}>
                      <p
                        style={{
                          fontSize: "13px",
                          marginBottom: "0",
                          fontWeight: "600",
                        }}
                      >
                        Email address
                      </p>
                      <input
                        style={{
                          height: "30px",
                          width: "100%",
                          paddingRight: "40px",
                        }}
                        type="text"
                        className={`${styles.textbox}`}
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={(e) => {
                          formik.setFieldValue("email", e.target.value);
                        }}
                      />
                      <div
                        style={{
                          marginTop:
                            formik.errors &&
                              typeof formik.errors["email"] === "string" &&
                              formik.touched["email"]
                              ? "3px"
                              : "0px",
                          marginBottom:
                            formik.errors &&
                              typeof formik.errors["email"] === "string" &&
                              formik.touched["email"]
                              ? "6px"
                              : "27px",
                        }}
                      >
                        <ErrorMessage
                          name={"email"}
                          errors={formik.errors}
                          touched={formik.touched}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6  col-12">
                    <div
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "13px",
                          marginBottom: "0",
                          fontWeight: "600",
                        }}
                      >
                        Phone Number
                      </p>
                      <PhoneInput
                        inputClass={styles.phoneInput}
                        dropdownClass={styles.dropDownStyle}
                        country={"us"}
                        value={formik.values.contact_no}
                        onChange={(value: string) => {
                          formik.setFieldValue("contact_no", value);
                        }}
                        containerClass={styles.phoneInputContainer}
                      />
                      <div
                        style={{
                          marginTop:
                            formik.errors &&
                              typeof formik.errors["contact_no"] === "string" &&
                              formik.touched["contact_no"]
                              ? "3px"
                              : "0px",
                          marginBottom:
                            formik.errors &&
                              typeof formik.errors["contact_no"] === "string" &&
                              formik.touched["contact_no"]
                              ? "6px"
                              : "27px",
                        }}
                      >
                        <ErrorMessage
                          name={"contact_no"}
                          errors={formik.errors}
                          touched={formik.touched}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`row col-12 col-md-12 ${styles.mobile}`}>
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "13px",
                        marginBottom: "0",
                        fontWeight: "600",
                      }}
                    >
                      Company Name
                    </p>
                    <input
                      style={{
                        height: "30px",
                        width: "100%",
                      }}
                      type="text"
                      className={` ${styles.textbox}`}
                      id="company_name"
                      name="company_name"
                      value={formik.values.company_name}
                      onChange={(e) => {
                        formik.setFieldValue("company_name", e.target.value);
                      }}
                    />
                    <div
                      style={{
                        marginTop:
                          formik.errors &&
                            typeof formik.errors["company_name"] === "string" &&
                            formik.touched["company_name"]
                            ? "3px"
                            : "0px",
                        marginBottom:
                          formik.errors &&
                            typeof formik.errors["company_name"] === "string" &&
                            formik.touched["company_name"]
                            ? "6px"
                            : "27px",
                      }}
                    >
                      <ErrorMessage
                        name={"company_name"}
                        errors={formik.errors}
                        touched={formik.touched}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`row d-flex justify-space-between col-md-12  col-12 ${styles.mobile}`}
                >
                  <div className="col-md-6  col-12">
                    <div style={{ textAlign: "left" }}>
                      <p
                        style={{
                          fontSize: "13px",
                          marginBottom: "0",
                          fontWeight: "600",
                        }}
                      >
                        Password
                      </p>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <input
                          style={{
                            height: "30px",
                            width: "100%",
                          }}
                          type={!formik.values.eye1 ? "password" : "text"}
                          className={` ${styles.textbox}`}
                          id="password1"
                          name="password1"
                          value={formik.values.password1}
                          onChange={(e) => {
                            formik.setFieldValue("password1", e.target.value);
                          }}
                        />
                        <button
                          type="button"
                          className={styles.passwordToggle}
                          onClick={() => {
                            formik.setFieldValue("eye1", !formik.values.eye1);
                          }}
                          tabIndex={-1}
                        >
                          {formik.values.password1.length !== 0 &&
                            (formik.values.eye1 ? (
                              <CustomTooltip title={"Hide Password"}>
                                <FaEyeSlash color="rgb(88, 24, 69)" />
                              </CustomTooltip>
                            ) : (
                              <div>
                                <CustomTooltip title={"Show Password"}>
                                  <FaEye color="rgb(88, 24, 69)" />
                                </CustomTooltip>
                              </div>
                            ))}
                        </button>
                      </div>

                      <div
                        style={{
                          marginTop:
                            formik.errors &&
                              typeof formik.errors["password1"] === "string" &&
                              formik.touched["password1"]
                              ? "3px"
                              : "0px",
                          marginBottom:
                            formik.errors &&
                              typeof formik.errors["password1"] === "string" &&
                              formik.touched["password1"]
                              ? "9px"
                              : "30px",
                        }}
                      >
                        <ErrorMessage
                          name={"password1"}
                          errors={formik.errors}
                          touched={formik.touched}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6  col-12">
                    <div
                      // className={`${styles.mobpad}`}
                      style={{ textAlign: "left" }}
                    >
                      <p
                        style={{
                          fontSize: "13px",
                          marginBottom: "0",
                          fontWeight: "600",
                        }}
                      >
                        Confirm Password
                      </p>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <input
                          style={{
                            height: "30px",
                            width: "100%",
                            paddingRight: "40px",
                          }}
                          type={!formik.values.eye2 ? "password" : "text"}
                          className={`${styles.textbox}`}
                          id="password2"
                          name="password2"
                          value={formik.values.password2}
                          onChange={(e) => {
                            formik.setFieldValue("password2", e.target.value);
                          }}
                        />
                        <button
                          type="button"
                          className={styles.passwordToggle}
                          onClick={() => {
                            formik.setFieldValue("eye2", !formik.values.eye2);
                          }}
                          tabIndex={-1}
                        >
                          {formik.values.password2.length !== 0 &&
                            (formik.values.eye2 ? (
                              <CustomTooltip title={"Hide Password"}>
                                <FaEyeSlash color="rgb(88, 24, 69)" />
                              </CustomTooltip>
                            ) : (
                              <div>
                                <CustomTooltip title={"Show Password"}>
                                  <FaEye color="rgb(88, 24, 69)" />
                                </CustomTooltip>
                              </div>
                            ))}
                        </button>
                      </div>

                      <div
                        style={{
                          marginTop:
                            formik.errors &&
                              typeof formik.errors["password2"] === "string" &&
                              formik.touched["password2"]
                              ? "3px"
                              : "0px",
                          marginBottom:
                            formik.errors &&
                              typeof formik.errors["password2"] === "string" &&
                              formik.touched["password2"]
                              ? "9px"
                              : "30px",
                        }}
                      >
                        <ErrorMessage
                          name={"password2"}
                          errors={formik.errors}
                          touched={formik.touched}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-center col-12 col-md-12"
                  style={{ marginTop: "10px" }}
                >
                  <div className="col-md-2"></div>
                  <div
                    className="col-12 col-md-9"
                    style={{
                      alignItems: "flex-start",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <input
                        style={{ marginRight: "5px" }}
                        type="checkbox"
                        checked={formik.values.terms_and_conditions}
                        onChange={() => {
                          formik.setFieldValue(
                            "terms_and_conditions",
                            !formik.values.terms_and_conditions
                          );
                        }}
                      />
                      {"  "}I agree to Zita
                      <a
                        style={{
                          margin: "0 5px",
                          // textDecoration: "none",
                          fontWeight: "700",
                          color: "#581845",
                        }}
                        target={"_blank"}
                        rel="noreferrer"
                        href="https://www.zita.ai/apiservice/terms-and-conditions"
                      >
                        Terms of Use
                      </a>
                      and
                      <a
                        style={{
                          margin: "0 5px",
                          // textDecoration: "none",
                          fontWeight: "700",
                          color: "#581845",
                        }}
                        target={"_blank"}
                        rel="noreferrer"
                        href="https://www.zita.ai/apiservice/privacy-policy"
                      >
                        Privacy Policy.
                      </a>
                    </div>
                    <div>
                      {formik.errors.terms_and_conditions1 &&
                        formik.touched.terms_and_conditions && (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              marginTop:
                                formik.errors.terms_and_conditions1 &&
                                  formik.touched.terms_and_conditions
                                  ? "3px"
                                  : "0px",
                            }}
                          >
                            {formik.errors.terms_and_conditions1}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div
                  style={{
                    marginTop:
                      formik.errors.terms_and_conditions1 &&
                        formik.touched.terms_and_conditions
                        ? "9px"
                        : "30px",
                  }}
                >
                  {loader ? (
                    <button
                      className={`btn ${styles.loginbtnloder}`}
                      type="button"
                    >
                      <span
                        className={`spinner-border spinner-border-sm ${styles.loderboder}`}
                        role="status"
                        style={{ width: "15px", height: "15px" }}
                      ></span>
                    </button>
                  ) : (
                    <button
                      className={`btn ${styles.loginbtn}`}
                      type="button"
                      onClick={() => formik.handleSubmit()}
                    >
                      Sign up
                    </button>
                  )}
                </div>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  justifyContent: "space-between",
                  padding: "0 33px",
                }}
                className="d-flex "
              >
                <div>
                  Already have an account?{" "}
                  <Link
                    className="fw-bold "
                    style={{
                      color: "#581845",
                      textDecoration: "none",
                    }}
                    to={"/"}
                  >
                    Sign in
                  </Link>
                </div>
                <div>
                  <CustomTooltip
                    title={"Email us for support at support@zita.ai"}
                  >
                    <a
                      href="mailto:support@zita.ai?subject=Help Needed"
                      className="fw-bold "
                      style={{
                        color: "#581845",
                        textDecoration: "none",
                      }}
                    >
                      Need Help?
                    </a>
                  </CustomTooltip>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "40px" }}>
            <footer className={`text-center ${styles.rights}`}>
              <div>App Version {version}</div>
            </footer>
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default SignupScreen;
