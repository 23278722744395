import React, { useEffect, useState } from "react";
import styles from "../Login/login.module.css";
import SvgZita from "../../icons/SvgZita";
import { Link, useNavigate } from "react-router-dom";
import { ResendCode, Signin } from "../../api";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CustomTooltip from "../../uikit/CustomTooltip/CustomTooltip";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import { getAccountSettingData } from "../../redux/reducers/AccountSettingSlice";
import { verifyToken } from "../../configuration";
import Loader from "../../uikit/Loader";
import { notify } from "../../uikit/toast";

const LoginScreen = () => {
  const version = process.env.REACT_APP_VERSION;
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailerror, setemailError] = useState<boolean>(false);
  const [emailerrortext, setemailerrortext] = useState<string>("");
  const [pwderror, setpwdError] = useState<boolean>(false);
  const [pwderrortext, setpwderrortext] = useState<string>("");
  const [responseerrortext, setresponseerrortext] = useState<string>("");
  const [loader, setloader] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [icon, seticon] = useState<boolean>(false);
  const [verification, setverification] = useState<boolean>(false);
  const navigate = useNavigate();
  const [overallloader, setoverallloader] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setoverallloader(true);
    const handleVerifyTokens = async () => {
      const accessToken = sessionStorage.getItem("access_token");
      const idToken = sessionStorage.getItem("id_token");

      if (accessToken && idToken) {
        try {
          const accessTokenPayload = await verifyToken(accessToken, "access");
          const idTokenPayload = await verifyToken(idToken, "id");
          if (accessTokenPayload && idTokenPayload) {
            navigate("/dashboard");
            setoverallloader(false);
          } else {
            navigate("/");
            setoverallloader(false);
          }
        } catch (err) {
          console.error("Error verifying tokens:", err);
          navigate("/");
          setoverallloader(false);
        }
      } else {
        console.error("No access token or ID token found in session storage.");
        navigate("/");
        setoverallloader(false);
      }
    };
    handleVerifyTokens();
  }, []);

  const handleSubmit = async () => {
    setemailError(true);
    setpwdError(true);
    if (email.length === 0) {
      setemailerrortext("Please enter your email.");
    }
    if (password.length === 0) {
      setpwderrortext("Please enter your password.");
    }
    if (!validateEmail(email) && email.length !== 0) {
      setemailerrortext("Please enter a valid email address.");
    }
    if (
      email.length !== 0 &&
      validateEmail(email) &&
      password.length <= 50 &&
      password.length >= 8 &&
      responseerrortext === ""
    ) {
      setloader(true);
      Signin(email, password)
        .then(async (res) => {
          sessionStorage.setItem("access_token", res.data.data.access_token);
          sessionStorage.setItem("id_token", res.data.data.id_token);
          sessionStorage.setItem("refresh_token", res.data.data.refresh_token);
          sessionStorage.setItem("email", email);
          if (
            res.data.data.user_profile.firstName === null ||
            res.data.data.user_profile.firstName === undefined ||
            res.data.data.user_profile.firstName === ""
          ) {
            sessionStorage.setItem(
              "user_name",
              res.data.data.user_profile.username
            );
          } else {
            sessionStorage.setItem(
              "user_name",
              res.data.data.user_profile.firstName +
                " " +
                res.data.data.user_profile.lastName
            );
          }

          dispatch(getAccountSettingData());
          setloader(false);
          navigate("/dashboard");
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.data.message ===
              "This account has not been verified yet."
            ) {
              setverification(true);
              setloader(false);
              setemailerrortext(error.response.data.data.message);
            } else {
              if (
                error.response.data.data.message ===
                  "This account has not been verified yet." ||
                error.response.data.data.message ===
                  "This email is not registered with Zita."
              ) {
                setemailerrortext(error.response.data.data.message);
                setloader(false);
              } else {
                setresponseerrortext(error.response.data.data.message);
                setloader(false);
              }
            }
          }
        });
    }
  };

  const emailhandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setresponseerrortext("");
    const value = e.target.value.trim();
    // console.log("error", e.target.value.trim());
    setEmail(value);
    if (value.length > 50) {
      setemailerrortext("Email must not exceed 50 characters.");
    } else if (!validateEmail(value) && value.length !== 0) {
      setemailerrortext("Please enter a valid email address.");
    } else if (value.length === 0) {
      setemailerrortext("Please enter your email.");
    } else {
      setemailerrortext("");
    }
  };
  const validateEmail = (value: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const passwordhandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setresponseerrortext("");
    seticon(true);
    const value = e.target.value.trim();
    // console.log("error", pwderrortext);
    setPassword(value);
    if (value.length >= 50) {
      setpwderrortext("Password must not exceed 50 characters.");
    } else if (value.length <= 7 && value.length !== 0) {
      setpwderrortext("Password must be at least 8 characters.");
    } else if (value.length === 0) {
      setpwderrortext("Please enter your password.");
      seticon(false);
    } else {
      setpwderrortext("");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };
  const resendotp = () => {
    ResendCode(email)
      .then((res) => {
        sessionStorage.setItem("ver-email", email);
        notify("Verification code resent successfully.", "success", 3000);
        setTimeout(() => {
          setloader(false);
          navigate("/code-verification");
        }, 3000);
      })
      .catch((error) => {
        console.error("error", error);
        setloader(false);
      });
  };
  return (
    <>
      {!overallloader ? (
        <div
          className={`container-fluid ${styles.bgimage}`}
          style={{
            backgroundImage: `url(/Images/loginbgimage.png)`,
            fontSize: "13px",
            // height: window.innerHeight,
          }}
        >
          <div
            className="d-flex justify-content-center"
            style={{ minHeight: "80vh" }}
          >
            <div
              className="text-center"
              style={{ marginTop: "3%", width: "380px" }}
            >
              <SvgZita width={150} height={80} />
              <div style={{ marginTop: "10%" }}>
                <div>
                  <h4 className="fw-bold">Sign in to your account</h4>
                  <p>Enter your account information to proceed.</p>
                </div>
                <div style={{ marginTop: "5%" }} className="text-start">
                  <div style={{ marginBottom: "5px" }}>
                    <label
                      htmlFor="email"
                      className="form-label"
                      style={{ fontWeight: "600" }}
                    >
                      Email address
                    </label>
                    <input
                      style={{
                        height: "44px",
                        maxWidth: "600px",
                        paddingRight: "40px",
                      }}
                      type="text"
                      className={`form-control ${styles.textbox}`}
                      id="email"
                      name="email"
                      value={email}
                      onChange={emailhandleChange}
                      onKeyDown={handleKeyDown} // Added keydown event
                    />
                    <div
                      style={{
                        marginTop:
                          (emailerror && emailerrortext) || verification
                            ? "3px"
                            : "0px",
                        marginBottom:
                          (emailerror && emailerrortext) || verification
                            ? "10px"
                            : "19px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {" "}
                        {emailerror && emailerrortext ? (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {emailerrortext}
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                      <div>
                        {verification &&
                          emailerrortext ===
                            "This account has not been verified yet." && (
                            <div
                              onClick={resendotp}
                              style={{
                                color: "#581845",
                                fontWeight: "700",
                                cursor: "pointer",
                              }}
                            >
                              Resend Verification
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row ">
                      <div className="col-md-6  col-6">
                        <label
                          htmlFor="password"
                          className="form-label col-md-6 "
                          style={{ fontWeight: "600" }}
                        >
                          Password
                        </label>
                      </div>
                      <div className="col-md-6  col-6 text-end">
                        <Link
                          to={"/forgot-password"}
                          className="fw-bold "
                          style={{
                            color: "#581845",
                            textDecoration: "none",
                          }}
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      <input
                        style={{
                          height: "44px",
                          maxWidth: "600px",
                          paddingRight: "40px",
                        }}
                        type={!showPassword ? "password" : "text"}
                        className={`form-control ${styles.textbox}`}
                        id="password"
                        name="password"
                        value={password}
                        onChange={passwordhandleChange}
                        onKeyDown={handleKeyDown} // Added keydown event
                      />
                      <button
                        type="button"
                        className={styles.passwordToggle}
                        onClick={togglePasswordVisibility}
                        tabIndex={-1}
                      >
                        {icon &&
                          (showPassword ? (
                            <CustomTooltip title={"Hide Password"}>
                              <FaEyeSlash color="rgb(88, 24, 69)" />
                            </CustomTooltip>
                          ) : (
                            <div>
                              <CustomTooltip title={"Show Password"}>
                                <FaEye color="rgb(88, 24, 69)" />
                              </CustomTooltip>
                            </div>
                          ))}
                      </button>
                    </div>
                    <div
                      style={{
                        marginBottom:
                          (!pwderrortext && responseerrortext) ||
                          (pwderror && pwderrortext)
                            ? "9px"
                            : "29px",
                      }}
                    >
                      {pwderror && pwderrortext && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {pwderrortext}
                        </div>
                      )}

                      {!pwderrortext && responseerrortext && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {responseerrortext}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    {loader ? (
                      <button
                        className={`btn ${styles.loginbtnloder}`}
                        type="button"
                      >
                        <span
                          className={`spinner-border spinner-border-sm ${styles.loderboder}`}
                          role="status"
                          style={{ width: "15px", height: "15px" }}
                        ></span>
                      </button>
                    ) : (
                      <button
                        className={`btn ${styles.loginbtn}`}
                        type="button"
                        onClick={() => handleSubmit()}
                      >
                        Sign in
                      </button>
                    )}
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      justifyContent: "space-between",
                    }}
                    className="d-flex "
                  >
                    <div>
                      Don't have an account?{" "}
                      <Link
                        className="fw-bold "
                        style={{
                          color: "#581845",
                          textDecoration: "none",
                        }}
                        to={"/sign-up"}
                      >
                        Sign up
                      </Link>
                    </div>
                    <div>
                      <CustomTooltip
                        title={"Email us for support at support@zita.ai"}
                      >
                        <a
                          href="mailto:support@zita.ai?subject=Help Needed"
                          className="fw-bold "
                          style={{
                            color: "#581845",
                            textDecoration: "none",
                          }}
                        >
                          Need Help?
                        </a>
                      </CustomTooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <footer className={`text-center ${styles.rights}`}>
              <div>App Version {version}</div>
              <div> © Sense7ai 2024 &middot; ALL RIGHTS RESERVED</div>
            </footer>
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default LoginScreen;
