import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./sidebar.module.css";
import SvgAuthenticate from "../../icons/SvgAuthenticate";
import SvgOverviewIcon from "../../icons/SvgOverviewIcon";
import SvgProducts from "../../icons/SvgProducts";
import SvgAnalytics from "../../icons/SvgAnalytics";
import SvgAccSettings from "../../icons/SvgAccSettings";
import SvgBilling from "../../icons/SvgBilling";
import SvgDocument from "../../icons/SvgDocument";
import SvgSignOut from "../../icons/SvgSignOut";
import { Signoutapi } from "../../api";
import Loader from "../../uikit/Loader";

const Sidebar = () => {
  const { pathname } = useLocation();
  const [sidebarHeight, setsidebarHeight] = useState(window.innerHeight - 67);
  const email = sessionStorage.getItem("email") || "";
  const navigate = useNavigate();
  const [isLoading, setloading] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setsidebarHeight(window.innerHeight - 67);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSignOut = () => {
    setloading(true);
    Signoutapi(email)
      .then((res) => {
        // console.log("response", res.data.data);
        sessionStorage.clear();
        setloading(false);
        navigate("/");
        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
          // console.log("dataaaa", error.response.data.data.message);
          setloading(false);
        }
      });
  };

  return (
    <>
      {isLoading && (
        <div className={`${styles.pageLoader}`}>
          <Loader />
        </div>
      )}
      <div
        className={`sidebar ${styles.sidebar} d-flex flex-column justify-content-between`}
        style={{ height: sidebarHeight }}
      >
        <ul className={styles.sideMenuList}>
          <Link to="dashboard" className="nav-link">
            <li
              className={
                pathname === "/dashboard"
                  ? `nav-link ${styles.menuItemSelected}`
                  : `nav-link ${styles.menuItem}`
              }
            >
              <div className={styles.sidebarIcon}>
                <SvgOverviewIcon
                  width={17}
                  height={17}
                  fill={pathname === "/dashboard" ? "#581845" : ""}
                />
              </div>
              <div className={`${styles.menuItemName} mt-1`}>Dashboard</div>
            </li>
          </Link>
          <Link to="api-products" className="nav-link">
            <li
              className={
                pathname === "/api-products"
                  ? `nav-link ${styles.menuItemSelected}`
                  : `nav-link ${styles.menuItem}`
              }
            >
              <div className={styles.sidebarIcon}>
                <SvgProducts
                  width={17}
                  height={17}
                  fill={pathname === "/api-products" ? "#581845" : ""}
                />
              </div>
              <div className={`${styles.menuItemName} mt-1`}>API Products</div>
            </li>
          </Link>
          <Link to="authentication" className="nav-link">
            <li
              className={
                pathname === "/authentication"
                  ? `nav-link ${styles.menuItemSelected}`
                  : `nav-link ${styles.menuItem}`
              }
            >
              <div className={styles.sidebarIcon}>
                <SvgAuthenticate
                  width={17}
                  height={17}
                  fill={pathname === "/authentication" ? "#581845" : ""}
                />
              </div>
              <div className={`${styles.menuItemName} mt-1`}>
                Authentication
              </div>
            </li>
          </Link>
          <Link to="analytics" className="nav-link">
            <li
              className={
                pathname === "/analytics"
                  ? `nav-link ${styles.menuItemSelected}`
                  : `nav-link ${styles.menuItem}`
              }
            >
              <div className={styles.sidebarIcon}>
                <SvgAnalytics
                  width={17}
                  height={17}
                  stroke={pathname === "/analytics" ? "#581845" : "#1E1E1E"}
                />
              </div>
              <div className={`${styles.menuItemName} mt-1`}>Analytics</div>
            </li>
          </Link>
          <Link to="/account-setting" className="nav-link">
            <li
              className={
                pathname === "/account-setting"
                  ? `nav-link ${styles.menuItemSelected}`
                  : `nav-link ${styles.menuItem}`
              }
            >
              <div className={styles.sidebarIcon}>
                <SvgAccSettings
                  width={17}
                  height={17}
                  fill={pathname === "/account-setting" ? "#581845" : ""}
                />
              </div>
              <div className={`${styles.menuItemName} mt-1`}>
                Account Settings
              </div>
            </li>
          </Link>
          <Link to="billing" className="nav-link">
            <li
              className={
                pathname === "/billing"
                  ? `nav-link ${styles.menuItemSelected}`
                  : `nav-link ${styles.menuItem}`
              }
            >
              <div className={styles.sidebarIcon}>
                <SvgBilling
                  width={17}
                  height={17}
                  fill={pathname === "/billing" ? "#581845" : ""}
                />
              </div>
              <div className={`${styles.menuItemName} mt-1`}>Billing</div>
            </li>
          </Link>
          <Link to="documentation" className="nav-link">
            <li
              className={
                pathname === "/documentation"
                  ? `nav-link ${styles.menuItemSelected}`
                  : `nav-link ${styles.menuItem}`
              }
            >
              <div className={styles.sidebarIcon}>
                <SvgDocument
                  width={17}
                  height={17}
                  stroke={pathname === "/documentation" ? "#581845" : ""}
                />
              </div>
              <div className={`${styles.menuItemName} mt-1`}>
                Documentations
              </div>
            </li>
          </Link>
        </ul>
        <ul style={{ marginBottom: "2.5rem" }}>
          <li className={`nav-link`}>
            {!isLoading ? (
              <div
                onClick={handleSignOut}
                className="nav-link d-flex align-items-center"
              >
                <div>
                  <SvgSignOut />
                </div>
                <div className={styles.menuSignOut}>Sign out</div>
              </div>
            ) : (
              <div className="nav-link d-flex align-items-center">
                <div>
                  <SvgSignOut />
                </div>
                <div className={styles.menuSignOut}>Sign out</div>
              </div>
            )}
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
